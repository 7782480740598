import { API } from "../backend";

export const signin = (mobile) => {
    const formData = new FormData();
    formData.append("mobile", mobile);
  
    return fetch(`${API}api/user/login/`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => console.log(err));
  };
  
  export const verify = (mobile, otp) => {
    const formData = new FormData();
      formData.append("mobile", mobile);
      formData.append("otp", otp);
  
    return fetch(`${API}api/user/verify/`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        console.log(response)
        return response.json();
      })
      .catch((err) => console.log(err));
  };

export const signup = (information) => {
  const formData = new FormData();

  for (const name in information) {
    formData.append(name, information[name]);
  }
  return fetch(`${API}api/user/register/`, {
    method: "POST",
    body: formData,
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getAllResidences = () => {
  return fetch(`${API}api/residence/`,
   { method: "GET" ,
   headers: new Headers({
      'Content-Type': 'application/json'
      }),})
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getAllHealthCentersForProvince = (province_id) => {
  return fetch(`${API}api/health/?province_id=${encodeURIComponent(province_id)}`,
   { method: "GET" ,
   headers: new Headers({
      'Content-Type': 'application/json'
      }),})
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getAllDoctorsForHealthCenters = (health_center_id) => {
  return fetch(`${API}api/doctor/?health_center_id=${encodeURIComponent(health_center_id)}`,
   { method: "GET" ,
   headers: new Headers({
      'Content-Type': 'application/json'
      }),})
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getUserInfo = () => {
  return fetch(`${API}api/user/get_user_info/`,
   { method: "GET" ,
   headers: new Headers({
      // 'Authorization': 'Token ' + Cookies.get('token'), 
      'Content-Type': 'application/x-www-form-urlencoded'
      }),})
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};