import React, {useState, useEffect, useRef} from 'react'

import "./Signup.css";

import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import Cookies from 'js-cookie';
import { FaRegCircleXmark } from 'react-icons/fa6';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faPersonCane } from '@fortawesome/free-solid-svg-icons'
import { signup } from '../apis/api';
import { Checkbox, Radio, Switch } from 'pretty-checkbox-react';

const Signup = ({ handleClose }) => {
    const [mobile, setMobile] = useState("")
    const [mobileSalamatyar, setMobileSalamatyar] = useState("")
    const [first_name, setFirst_name] = useState("")
    const [last_name, setLast_name] = useState("")
    const [email, setEmail] = useState("")
    const [isNumberExists, setIsNumberExists] = useState(false);
    const [isEmailExists, setIsEmailExists] = useState(false);
    const [otherErrors, setOtherErrors] = useState(false);
    const [formType, setFormType] = useState('individual');
    
    const [showDone, setShowDone] = useState(false);



    const handleShowDone = () => {
        setShowDone(true);
    };
    const onSubmit = (event) => {
        event.preventDefault();
        signup({ mobile, first_name, last_name, email })
          .then((data) => {
              if(data.message == "OTP was sent"){
                handleShowDone(true);
                setIsNumberExists(false);
                setIsEmailExists(false);
                setOtherErrors(false);
              }
              else if(data.message == 'user with this mobile already exists.'){
                setIsNumberExists(true);
                setIsEmailExists(false);
                setOtherErrors(false);
              }
              else if(data.message == 'user with this email already exists.'){
                setIsEmailExists(true);
                setIsNumberExists(false);
                setOtherErrors(false);
              }
              else{
                setOtherErrors(true);
                setIsEmailExists(false);
                setIsNumberExists(false);
              }
          })
          .catch((e) => console.log(e));
      };

  
      return (
        <div className="modal-add-event display-block">
          <section className="modal-main-add-event">
                <div className='xmark-font-awsome-right'>
                    <button className='submit-filter-but'>
                        <FontAwesomeIcon onClick={handleClose} className="fontawesome-icon fa-2xl" icon={ faXmark } color="#DE3163"/> 
                    </button>     
                </div>
                <div className="form-switch">
                            <button className={`form-switch-button ${formType === 'individual' ? 'active' : 'disable'}`} onClick={() => setFormType('individual')}>
                                اشخاص
                            </button>
                            <button className={`form-switch-button ${formType === 'business' ? 'active' : 'disable'}`} onClick={() => setFormType('business')}>
                                کسب و کارها
                            </button>
                </div>
                {!showDone && formType == 'individual' && <div className='items-list-div'>
                    <ul className='items-list-ul'>

                        <li>
                        <label className='select-label-figma'>نام</label>
                        <br />
                        <input value={first_name} onChange={(e) => {setFirst_name(e.target.value);}} className='filter-elements1' type="text" />
                        </li>
                        <li>
                        <label className='select-label-figma'>نام خانوادگی</label>
                        <br />
                        <input value={last_name} onChange={(e) => {setLast_name(e.target.value);}} className='filter-elements1' type="text" />
                        </li>
                        <li>
                        <label className='select-label-figma'>شماره موبایل</label>
                        <br />
                        <input maxLength={11} value={mobile} onChange={(e) => {setMobile(e.target.value);}} className='filter-elements1' type="text" />
                        </li>
                        {/* <li>
                        <label className='select-label-figma'>شماره موبایل</label>
                        <br />
                        <input maxLength={11} value={mobile} onChange={(e) => {setMobile(e.target.value);}} className='filter-elements1' type="text" />
                        </li> */}
                        <li>
                        <label className='select-label-figma'>ایمیل</label>
                        <br />
                        <input value={email} onChange={(e) => {setEmail(e.target.value);}} className='filter-elements1' type="text" />
                        </li>


                    </ul>
                    {isNumberExists && <div className="no-number">
                    <p className="no-number">
                      شماره وارد شده ثبت شده است.
                    </p>
                  </div>}

                    {isEmailExists && <div className="no-number">
                            <p className="no-number">
                            ایمیل وارد شده ثبت شده است.
                            </p>
                        </div>}
                    {otherErrors && <div className="no-number">
                            <p className="no-number">
                            داده های ورودی را بررسی کنید.
                            </p>
                        </div>} 
                <div>
                    <button disabled={!first_name || !last_name || !mobile || !email} className={(first_name && last_name && mobile && email) ? 'submit-changes' : 'submit-changes-disabled'} onClick={onSubmit}>تایید</button>
                </div>
                </div>}

                {!showDone && formType == 'business' && <div className='items-list-div'>
                <ul className='items-list-ul'>
                  <li>
                  <label className='select-label-figma'>نام شرکت</label>
                  <br />
                  <input disabled={true} className='filter-elements1' type="text" />
                  </li>
                  <li>
                  <label className='select-label-figma'>شماره تماس</label>
                  <br />
                  <input disabled={true} className='filter-elements1' type="text" />
                  </li>
                  <li>
                  <label className='select-label-figma'>ایمیل کاری</label>
                  <br />
                  <input disabled={true} className='filter-elements1' type="text" />
                  </li>

                  </ul>
                  <button className={'submit-changes-disabled'}>ثبت درخواست همکاری (به زودی...)</button>

                </div>
                }
                    


                {showDone && <div className='items-list-div'>

                    <div>
                        <p className="thanks-text">
                            از شما بابت ثبت اطلاعات ممنونیم. به زودی با شما در ارتباط خواهیم بود.
                        </p>
                    </div>
                <div>
                    <button className={'thanks-button'} onClick={handleClose}>خواهش میکنم!</button>
                </div>
                </div>}
            
          </section>
        </div>
      );
    };

export default Signup;
