import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { InputField } from "../../../components/InputField";
import { useLoginUserMutation } from "../../../hooks/users/useLoginUserMutation";
import { setUserInfoInLocalStorage } from "../../../utils/jwtToken";
import { FormDataLogin } from "../types";
import { signin, verify } from "../../../apis/api"
import Cookies from 'js-cookie';

export const LoginForm = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingVerify, setIsLoadingVerify] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormDataLogin>({
    mobile: "",
    otp: "",
  });
  const [signinOrVerify, setSigninOrVerify] = useState(1);

  const navigate = useNavigate();

  const navigateBlog = () => {
    navigate('/blog/me/blogs');
  };

  const mobileOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFormData({ ...formData, mobile: e.target.value });
  };

  const OTPOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFormData({ ...formData, otp: e.target.value });
  };

  const loginUserOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (formData.mobile.length === 0) {
      toast.error("شماره موبایل خود را وارد کنید");
    } else {
      setIsLoading(true);
      const data = {
        mobile: formData.mobile,
      };

      signin(formData.mobile)
      .then((data: { message: string }) => {
        console.log(data);
        if (data.message === "There is no user with this phone number") {
          toast.error("شماره مورد نظر یافت نشد.");
          setIsLoading(false);
        } else if (data.message === "OTP was sent") {
          setIsLoading(false);
          setSigninOrVerify(2);
        }
      })
      .catch((e: Error) => console.log(e));
    }
  };

  const VerifyUserOnSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    // setValuesOtp({ ...valuesOtp});
    verify(formData.mobile, formData.otp)
      .then((data: { message: string, token: string, id: string, user_type: string}) => {
        console.log(data)
        if(data.message == "OTP is wrong"){
          toast.error("کد وارد شده صحیح نیست.");
          setIsLoadingVerify(false);
        }
        else if(data.message == "OTP has expired"){
          toast.error("کد شما منقضی شده است.");
          setIsLoadingVerify(false);
        }
        else{
          Cookies.set('token', data.token, { expires: 365 });
          Cookies.set('userId', data.id, { expires: 365 });
          Cookies.set('userType', data.user_type, { expires: 365 });
          navigateBlog();
        }

      })
      .catch((e: Error) => console.log(e));
  };

  return (
    <div style={{ width: '100%' }}>
      {signinOrVerify == 1 && <div>
        <form onSubmit={loginUserOnSubmit} className="w-full flex flex-col items-center">
          <InputField label="شماره موبایل" type="text" name="mobile" placeholder="شماره خود را وارد کنید" onChangeHandler={mobileOnChange} />

          <button type="submit" className="w-1/2 h-10 mt-4 rounded-full bg-lightpurple hover:bg-darkpurple font-bold">
            {isLoading ? <ClipLoader color="#000000" loading={isLoading} size={15} aria-label="loading-spinner" data-testid="loader" /> : "ورود"}
          </button>
        </form>
      </div>}
      {signinOrVerify == 2 && <div>
        <form onSubmit={VerifyUserOnSubmit} className="w-full flex flex-col items-center">
          <InputField label="کد ارسالی" type="text" name="mobile" placeholder="کد ارسال شده را وارد کنید" onChangeHandler={OTPOnChange} />

          <button type="submit" className="w-1/2 h-10 mt-4 rounded-full bg-lightpurple hover:bg-darkpurple font-bold">
            {isLoadingVerify ? <ClipLoader color="#000000" loading={isLoadingVerify} size={15} aria-label="loading-spinner" data-testid="loader" /> : "تایید"}
          </button>
        </form>
      </div>}
    </div>
  );
};
