import React from "react";
import { categoryData } from "../../../utils/categories";
import { CategorySelectionProps } from "../types";

export const CategorySelection = ({ catIndex, setCatIndex }: CategorySelectionProps): JSX.Element => {
  const categories = [{ label: "همه", name: "همه", value: "all" }, ...categoryData];

  return (
    <div className="category-blog" style={{marginTop: "75px"}}>
      <div className="grid grid-cols-5 sm:grid-cols-11 content-center gap-2 p-2">
        {categories.map((category, index) => (
          <button
            key={index}
            onClick={() => setCatIndex({ index: index, cat: category.value })}
            className={`${
              catIndex.index === index ? "border-green-300 shadow-md shadow-slate-300 bg-green-200 scale-110 duration-300" : ""} 
              border-2 rounded-full flex justify-center items-center font-bold text-[0.45rem] sm:text-[0.6rem] p-2 w-full 
              hover:bg-green-200`}
          >
            {category.name.charAt(0).toUpperCase() + category.name.slice(1)}
          </button>
        ))}
      </div>
    </div>

  );
};
