import React, {useState, useEffect, useRef} from 'react'
import { AiTwotoneFolderOpen } from "react-icons/ai";
import { BsFillBookmarkCheckFill } from "react-icons/bs";
import { FaPenNib } from "react-icons/fa";
import { RiArrowDropDownFill, RiArrowDropUpFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDeleteUserMutation } from "../../../hooks/users/useDeleteUserMutation";
import { useGetUserDataQuery } from "../../../hooks/users/useGetUserDataQuery";
import { cleanUserInfoFromLocalStorage } from "../../../utils/jwtToken";
import Cookies from 'js-cookie';

export const UserProfile = (): JSX.Element => {
  const authToken = Cookies.get("token") || null;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<any>();

  const navigate = useNavigate();
  const navigateBlog = () => {
    navigate('/blog');
  };
  const { data: userData } = useGetUserDataQuery(authToken, (_: any) => {
    toast.error("Error in fetching user data. Please try again later.");
  });

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleOptionsOnClick = () => {
    setIsOpen((prev) => !prev);
  };

  const updateCredentialsOnClick = () => {
    navigate("/me/updatecredentials");
  };

  const logoutOnClick = () => {
    Cookies.remove('token');
    Cookies.remove('userId');
    Cookies.remove('userType');
    navigateBlog();
  };

  const createBlogOnClick = () => {
    navigate("/blog/create");
  };

  const viewSavedBlogOnClick = () => {
    navigate("/blog/me/readinglist");
  };

  const viewMyBlogsOnClick = () => {
    navigate("/blog/me/blogs");
  };

  return (
    <>
      {/** Write icon */}
      <div onClick={createBlogOnClick} className="flex justify-between items-center gap-x-2 p-2 bg-purple-200 hover:bg-purple-300 hover:cursor-pointer hover:scale-110 transition-all rounded-md">
        <FaPenNib size={14} />
        <p className="text-sm">پست جدید</p>
      </div>

      {/** Saved blogs icon */}
      {/* <div onClick={viewSavedBlogOnClick} className="flex justify-between items-center gap-x-2 p-2 rounded-full cursor-pointer hover:scale-125 transition-all">
        <BsFillBookmarkCheckFill size={14} />
        <p className="text-sm">Saved</p>
      </div> */}

      {/** My blogs icon */}
      <div onClick={viewMyBlogsOnClick} className="flex justify-between items-center gap-x-2 p-2 rounded-full cursor-pointer hover:scale-125 transition-all">
        <AiTwotoneFolderOpen size={14} />
        <p className="text-sm">پست های من</p>
      </div>

      {/** Profile image & username & dropdown options */}
      <div ref={dropdownRef} onClick={handleOptionsOnClick} className="flex justify-between gap-x-2 items-center p-2 cursor-pointer relative">
        <img src={userData?.profile_image} alt="profileimage" className="w-10 h-10 rounded-full" />
        {/* <p className="font-secondary text-sm over overflow-hidden">@ {userData?.author_full_name}</p> */}
        {isOpen ? <RiArrowDropUpFill size={30} /> : <RiArrowDropDownFill size={30} />}
        {isOpen && (
          <div className="w-full absolute left-6 top-16 p-1 bg-white shadow-md z-10 rounded-md text-[0.8rem]">
            <ul className="space-y-1">
              <li onClick={logoutOnClick} className="hover:bg-darkyellow rounded-md p-2">
                خروج
              </li>
            </ul>
          </div>
        )}
      </div>
    </>
  );
};
