import React, {useState, useEffect, useRef} from 'react'
import { useSearchParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { ErrorMessage } from "../../components/ErrorMessage";
import { useSearchBlogQuery } from "../../hooks/blogs/useSearchBlogQuery";
import { BlogCard } from "./components/BlogCard";
import Cookies from "js-cookie";
import Title from "../../base/Title";
import { useNavigate } from 'react-router-dom';
import { SlMagnifier } from 'react-icons/sl';
export const ViewSearchedBlogs = (): JSX.Element => {
  const inputRef = useRef<any>();

  const navigate = useNavigate();
  const authToken = Cookies.get("token") || null;
  const [searchParam, _] = useSearchParams();
  const { data: blogs, isError, isLoading } = useSearchBlogQuery(searchParam.get("title")!, authToken);
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  if (isError) {
    return <ErrorMessage />;
  }

  const searchOnClick = () => {
    const title = inputRef.current.value;
    navigate(`/blog/search?title=${title}`);
  };

  return (
    <>
    {Cookies.get("userType") == "blogger" ? "" : <div className='title-blog-mobile block sm:hidden'><Title title="مقالات آموزشی" /></div>}
    {Cookies.get("userType") == "blogger" ? "" : <div className='title-blog-desktop hidden sm:block'><Title title="مقالات آموزشی" /></div>}
    <div className="mx-auto max-w-[1440px] mt-20 w-full">
    <div className={`search-div ${isFocused ? 'focused' : ''}`}>
        <input
          type="text"
          placeholder="جست و جو"
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          value={inputValue}
          ref={inputRef}
          className={`search-input ${isFocused ? 'purple-border' : ''}`}
        />
        <SlMagnifier
          onClick={searchOnClick}
          className={`search-icons ${isFocused && inputValue != '' ? 'purple-icon' : ''}`}
          title="جست و جو"
        />
      </div>
      {isLoading ? (
        <div className="flex flex-col justify-center items-center">
          <ClipLoader color="#000000" loading={isLoading} size={25} aria-label="loading-spinner" data-testid="loader" />
        </div>
      ) : (
        blogs?.map((blogData: any, index: number) => (
          <div key={index} className="flex flex-col items-center">
            <BlogCard blogData={blogData} />
          </div>
        ))
      )}
    </div>
    </>
  );
};
