import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlay, faCircleStop } from '@fortawesome/free-solid-svg-icons'
import './Slogan.css';
import { useTranslation } from 'react-i18next';

export default function Slogan() {
    const videoRef = useRef(null);
    const [isPaused, setIsPaused] = useState(false);
  
    const handleStopClick = () => {
      if (videoRef.current) {
        videoRef.current.pause();
        setIsPaused(true);
      }
    };
  
    const handleResumeClick = () => {
      if (videoRef.current) {
        videoRef.current.play();
        setIsPaused(false);
      }
    };
  const { t } = useTranslation();

  return (
    <div id="teaser" className="background-component">
      <div className="logo-container">
        <img src={require("../../images/A_Logo.png")} alt="Logo" className="logo-video" />
      </div>
      <video ref={videoRef} className="background-component-video" src="/Videos/New_Logo_Clip.mp4" autoPlay loop muted />
      <div className="slogan">
      {t('Slogan')}
      </div>
      {!isPaused ? (
        <div className="stop-button">
            <FontAwesomeIcon className="fa-2xl" onClick={handleStopClick} icon={ faCircleStop } color="white" title=""/>
        </div>
        // <button className="stop-button" onClick={handleStopClick}>Stop Video</button>
      ) : (
        <div className="resume-button">
            <FontAwesomeIcon className="fa-2xl" onClick={handleResumeClick} icon={ faCirclePlay } color="white" title=""/>
        </div>

      )}
    </div>
  );
}
