import React, { Suspense } from 'react';
import './styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style/flexboxgrid.min.css";
import './style/index.css';
import RoutesFile from './Routes';
import ReactDOM from "react-dom";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { BrowserRouter } from 'react-router-dom';

const Loading = () => <div>Loading...</div>;

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <RoutesFile />
      </Suspense>
    </BrowserRouter>
  </I18nextProvider>,
  document.getElementById("root")
);
