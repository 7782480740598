import React from "react";
import styled from "styled-components";
// Components
import BlogBox from "../Elements/BlogBox";
import FullButton from "../Buttons/FullButton";
import TestimonialSlider from "../Elements/TestimonialSlider";
import { useTranslation } from 'react-i18next';
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";

export default function Blog() {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateBlog = () => {
      navigate('/blog/');
  };

  return (
    <Wrapper className="blog-div" id="blog">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <p className="font40 extraBold">{t('BlogTitle')}</p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title={t('FirstBlogTitle')}
                text={t('FirstBlogDesc')}
                tag={t('FirstBlogHashTag')}
                author={t('FirstBlogAuthor')}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title={t('SecondBlogTitle')}
                text={t('SecondBlogDesc')}
                tag={t('SecondBlogHashTag')}
                author={t('SecondBlogAuthor')}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <BlogBox
                title={t('ThirdBlogTitle')}
                text={t('ThirdBlogDesc')}
                tag={t('ThirdBlogHashTag')}
                author={t('ThirdBlogAuthor')}
              />
            </div>
          </div>
          <div className="row flexCenter">
            <div onClick={navigateBlog} style={{ margin: "50px 0", width: "200px" }}>
              <FullButton title={t('More')}/>
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg" style={{padding: '50px 0'}}>
        <div className="container">
          <HeaderInfo>
            <p className="font40 extraBold">{t('Quotes')}</p>
            {/* <p className="font13">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
              <br />
              labore et dolore magna aliquyam erat, sed diam voluptua.
            </p> */}
          </HeaderInfo>
          <TestimonialSlider />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;