/* eslint-disable jsx-a11y/img-redundant-alt */
import React, {useState, useEffect, useRef} from 'react'
import { HiFire } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { timeDiff } from "../../../utils/relativeTime";
import ReactQuill from 'react-quill';

export const BlogCard = ({ blogData }: any): JSX.Element => {
  const navigate = useNavigate();


  const viewBlogOnClick = () => {
    navigate(`/blog/view/${blogData.id}/${blogData.slug}`);
  };

  const removeImagesFromContent = (content: string): string => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;
  
    const images = tempDiv.getElementsByTagName('img');
    Array.from(images).forEach((image) => {
      image.remove();
    });
  
    let text = tempDiv.textContent || tempDiv.innerText;
    text = text.trim();

    if (text.length > 50) {
      text = text.substring(0, 50) + '...';
    }

    return text;
  };

  return (
    <div
      onClick={viewBlogOnClick}
      style={{
        width: "90vw",
        cursor: "pointer",
        border: "5px dashed #22baa9",
        borderRadius: "50px"
      }}
      className="w-[95%] sm:w-full mt-4 flex flex-col sm:flex-row justify-between items-center p-4 bg-white hover:scale-105 duration-300 rounded-md border-[1.5px] border-slate-200 shadow-md shadow-slate-300"
    >
      {/** Cover image (move this above blog details on small screens) */}
      <img
        src={blogData["cover_image"]}
        alt="cover-image"
        className="w-full sm:w-[60%] h-[20rem] sm:h-[30rem] rounded-md object-cover mb-4 sm:mb-0"
      />
  
      {/** Blog details */}
      <div className="w-full sm:w-[40%] h-auto sm:h-[30rem] relative flex flex-col">
        {/** Author info */}
        <div className="flex justify-start items-center pl-2">
          <img
            src={blogData["author_profile_image"]}
            alt="author-profile-image"
            className="w-7 h-7 sm:w-10 sm:h-10 rounded-full"
          />
          <p className=" text-sm p-2">{blogData["author_full_name"]}</p>
        </div>
  
        {/** Title & subtitle */}
        <h2 className="p-2 font-bold text-sm sm:text-lg sm:whitespace-nowrap sm:overflow-hidden sm:overflow-ellipsis cursor-pointer w-full">
          {blogData.title}
        </h2>
        <p className="p-2 text-[0.55rem] sm:text-[0.75rem] hidden sm:block text-justify whitespace-nowrap overflow-hidden overflow-ellipsis">
          {blogData.subtitle}
        </p>
        <p className="p-2 text-[0.55rem] sm:text-[1rem]" style={{ textAlign: "right" }}>
          {removeImagesFromContent(blogData?.content || '')}
        </p>
  
        {/** Date & likes */}
        <div className="flex justify-start items-center w-full sm:absolute sm:bottom-0">
          {/** Date */}
          <p className="p-2 text-[0.6rem] sm:text-[0.7rem]">
            {timeDiff(new Date(blogData.created_at).valueOf())}
          </p>
  
          {/** Likes */}
          <div className="flex justify-between items-center p-2">
            {/* <HiFire className="w-4" /> */}
            {/* <p className="text-sm">{Intl.NumberFormat("en", { notation: "compact" }).format(blogData["applaud_count"])}</p> */}
          </div>
        </div>
      </div>
    </div>
  );
  
};
