import React, {useState, useEffect, useRef} from 'react'
import ReactPaginate from "react-paginate";
import { ClipLoader } from "react-spinners";
import { ErrorMessage } from "../../components/ErrorMessage";
import { useGetAllBlogsQuery } from "../../hooks/blogs/useGetAllBlogsQuery";
import { BlogCard } from "./components/BlogCard";
import { CategorySelection } from "./components/CategorySelection";
import Cookies from 'js-cookie';
import Title from '../../base/Title';
import { Search } from '../home/components/Search';
import { useNavigate } from 'react-router-dom';
import { SlMagnifier } from 'react-icons/sl';

export const ViewAllBlogs = (): JSX.Element => {
  const inputRef = useRef<any>();

  const navigate = useNavigate();

  const [catIndex, setCatIndex] = useState<any>({
    index: 0,
    cat: "all",
  });


  const [page, setPage] = useState<number>(1);
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const { data: blogs, isError: isErrorFetchBlog, isLoading: isLoadingFetchBlog } = useGetAllBlogsQuery(catIndex.cat, page);


  const pageChangeOnChange = (selectedItem: { selected: number }) => {
    setPage(selectedItem.selected + 1);
  };

  if (isErrorFetchBlog) {
    return <ErrorMessage />;
  }

  const searchOnClick = () => {
    const title = inputRef.current.value;
    navigate(`/blog/search?title=${title}`);
  };

  return (
    <>
    {Cookies.get("userType") == "blogger" ? "" : <div className='title-blog-mobile block sm:hidden'><Title title="مقالات آموزشی" /></div>}
    {Cookies.get("userType") == "blogger" ? "" : <div className='title-blog-desktop hidden sm:block'><Title title="مقالات آموزشی" /></div>}
    <div className="mx-auto max-w-[1440px]">
      
      <CategorySelection catIndex={catIndex} setCatIndex={setCatIndex} />
      <div className={`search-div ${isFocused ? 'focused' : ''}`}>
        <input
          type="text"
          placeholder="جست و جو"
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          value={inputValue}
          ref={inputRef}
          className={`search-input ${isFocused ? 'purple-border' : ''}`}
        />
        <SlMagnifier
          onClick={searchOnClick}
          className={`search-icons ${isFocused && inputValue != '' ? 'purple-icon' : ''}`}
          title="جست و جو"
        />
    </div>
      {isLoadingFetchBlog ? (
        <div className="flex flex-col justify-center items-center">
          <ClipLoader color="#000000" loading={isLoadingFetchBlog} size={25} aria-label="loading-spinner" data-testid="loader" />
        </div>
      ) : (
        blogs?.results?.result?.map((blogData: any, index: number) => (
          <div key={index} className="flex flex-col items-center">
            <BlogCard blogData={blogData} />
          </div>
        ))
      )}

      <div className="mt-8 p-2">
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          pageCount={blogs?.results["total_pages"]}
          marginPagesDisplayed={4}
          pageRangeDisplayed={6}
          onPageChange={pageChangeOnChange}
          containerClassName={"rounded-md flex justify-center gap-2"}
          pageClassName={"border-[0.8px] border-slate-300 text-green-400 font-bold w-[1.5rem] h-[1.5rem] text-center rounded-md hover:bg-green-200"}
          previousClassName={"text-green-500 font-bold w-[1.5rem] h-[1.5rem] text-center rounded-md border-[0.8px] border-slate-300 hover:bg-green-200"}
          nextClassName={"text-green-500 font-bold w-[1.5rem] h-[1.5rem] text-center rounded-md border-[0.8px] border-slate-300 hover:bg-green-200"}
          breakClassName={"text-green-500"}
          activeClassName={"bg-green-200"}
        />
      </div>
    </div>
    </>

  );
};
