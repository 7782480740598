import React, {useState, useEffect, useRef} from 'react'
import { Outlet } from "react-router-dom";
// import { Footer } from "./components/Footer";
// import Footer from "../../base/Footer";
// import Title from "../../base/Title";
import { Navbar } from "./components/Navbar";
// import Header from '../../base/Header';
import Cookies from 'js-cookie';
import "./homeStyle.css"
import TopNavbar from '../../components/Nav/TopNavbar';
import BlogNavbar from '../../components/Nav/BlogNavbar';
// import Back from '../../base/Back';
export const Home = (): JSX.Element => {
  return (
    <div className="flex flex-col items-center justify-between h-screen">
      {Cookies.get("userType") == "blogger" ? <Navbar /> : <BlogNavbar />}
      {/* <Navbar /> */}
      {/* <Back /> */}
      <Outlet />
      {/* <Footer /> */}
    </div>
  );
};
