import React from "react";
import Slider from "react-slick";
import styled from "styled-components";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStethoscope, faUserNurse, faUserDoctor, faBriefcaseMedical, faHospitalUser, faHospital } from '@fortawesome/free-solid-svg-icons'

export default function ClientSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 2,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="medical-icons">
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <FontAwesomeIcon className="fa-2xl" icon={ faStethoscope } color="#6b6a7e" title=""/>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <FontAwesomeIcon className="fa-2xl" icon={ faUserDoctor } color="#6b6a7e" title=""/>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <FontAwesomeIcon className="fa-2xl" icon={ faUserNurse } color="#6b6a7e" title=""/>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <FontAwesomeIcon className="fa-2xl" icon={ faHospitalUser } color="#6b6a7e" title=""/>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <FontAwesomeIcon className="fa-2xl" icon={ faBriefcaseMedical } color="#6b6a7e" title=""/>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <FontAwesomeIcon className="fa-2xl" icon={ faHospital } color="#6b6a7e" title=""/>
        </LogoWrapper>
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 100%;
  height: 60px;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
const ImgStyle = styled.img`
  width: 100%;
  height: 100%;
  padding: 10%;
`;