import React, {useState, useEffect, useRef} from 'react'

import "./Title.css";

const Title = ({title}) => {

    return (
    <>
        <div className='page-headers'>
            <p className='page-headers-text'>
             {title}
            </p>
        </div>
    </>
    )
}

export default Title;